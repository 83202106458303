import React from 'react'
import { Helmet } from 'react-helmet'
import Theme, { Container, Layout } from '../components'

const AppointmentPage = (): React.ReactElement => (
  <>
    <Helmet>
      <script
        async
        defer
        type="text/javascript"
        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
      />
    </Helmet>
    <Theme>
      <Layout>
        <Container className="">
          <div
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/mathias-born?embed=true"
          />
        </Container>
      </Layout>
    </Theme>
  </>
)

export default AppointmentPage
